import {Injectable, OnDestroy} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {map, takeUntil} from 'rxjs/operators';
import {InvoicesRequest} from '../../models/invoices-request.model';
import {DocRequest} from '../../models/doc-request.model';
import {DocTypesRequest} from '../../models/doc-types-request.model';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {Subject} from 'rxjs';
import {OlInvoicesRequest} from '../../models/invoices/ol-invoices-request.model';
import {Response} from '../../models/response.model';
import {FindPdfRequest} from '../../models/findPdf-request.model';

@Injectable({
    providedIn: 'root',
})
export class InvoicesRestService extends RestServiceAbstract implements OnDestroy {

    public ngUnsubscribe$: Subject<void> = new Subject<void>();

    public requestOldDocuments(request: OlInvoicesRequest) {
        return this.post('/resource/documents/oldDocuments', request).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    /**
     * Performs a request using the remote API.
     *
     * @returns {Observable<Response>}
     * @param request InvoicesRequest
     * @throws HttpErrorResponse
     */
    public searchDocuments(request: InvoicesRequest): Observable<Response> {
        this.log.info('4. InvoicesService:searchDocuments:params ', request);
        return this.get(`/resource/documents/searchDocuments`, {params: request}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public recentDocuments(request: any): Observable<Response> {
        return this.get(`/resource/documents/recent`, {params: request}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    /**
     * Required by invoices list to request invoice download
     *
     * @returns {Observable<any>}
     * @param request
     */
    public getDocument(request: DocRequest) {
        this.get('/resource/documents/pdfDocument', {
            params: request,
            observe: 'response',
            responseType: 'blob'
        }).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.hasOwnProperty('body')) {
                    const blob = new Blob([response.body], {type: 'application/pdf'});
                    // TODO: show pdf in new tab
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    const filename = request.docType + '-' + request.docNr + '-' + request.docDate + '.pdf';
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, filename);
                    } else {
                        link.download = filename;
                        link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
                    }
                    link.remove();
                }
            }
        );
    }

    /**
     * @returns {Observable<Blob>}
     * @data request
     */
    public findPdf(request: FindPdfRequest) {
        this.postAndGetBlob('/resource/documents/find-pdf', request)
            .subscribe(
                (response: any) => {
                    if (response && response.type === 'application/pdf') {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(response);
                        const date = request.startDate.toISOString().substr(0, 10);
                        const filename = request.documentType + '-' + request.documentNumber + '-' + date + '.pdf';
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(response, filename);
                        } else {
                            link.download = filename;
                            link.dispatchEvent(new MouseEvent(`click`, {
                                bubbles: true,
                                cancelable: true,
                                view: window
                            }));
                        }
                        link.remove();
                    }
                }
            );
    }

    /**
     * Required by search form to request selectable document types
     *
     * @returns {Observable<any>}
     */
    public getDocumentTypes(request: DocTypesRequest) {
        return this.get(`/resource/documents/types`, {params: request}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    /**
     * Required by search form to request selectable document types
     *
     * @returns {Observable<any>}
     */
    public getAllDocumentTypes() {
        return this.get(`/resource/documents/allDocTypes`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public getLatestDocumentsForIdf(idf) {
        return this.get('/resource/documents/latest', {params: {idfs: idf}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }));
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properlly.
        this.ngUnsubscribe$.complete();
    }
}

