<div id="content" class="mb-0" *ngIf="page?.active">
    <img *ngIf="page?.banner_image" src="{{page.banner_image}}" alt="{{page.page_title}}">
    <div class="panel" *ngIf="!loading">
        <div class="row mb-m" *ngIf="page.back_link">
            <a href="{{page.back_link}}">
                <i class="material-icons"> keyboard_arrow_left </i> Zurück
            </a>
        </div>
        <h1 *ngIf="page.show_title">{{page.page_title}}</h1>
        <app-page-content [body]="body" [public]="page.public" [discountLevel]="discountLevel" *ngIf="link !== 'leistungen' && link !== 'wissen'"></app-page-content>
<!--        <app-monatsaktion *ngIf="link === 'monatsaktions'"></app-monatsaktion>-->
        <app-linda-leistungen *ngIf="link == 'leistungen'"></app-linda-leistungen>
        <app-linda-wissen *ngIf="link == 'wissen'"></app-linda-wissen>
    </div>
</div>
<app-page-footer [foot]="footer" *ngIf="!loading"></app-page-footer>
