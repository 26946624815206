import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-month-action',
  templateUrl: './digital-month-action.component.html',
  styleUrls: ['./digital-month-action.component.scss']
})
export class DigitalMonthActionComponent implements OnInit {

  @Input() discountLevel

  constructor() { }

  ngOnInit(): void {
  }

}
