<ng-container *ngIf="discountLevel !== '9'">
  <div class="row mt-s" *ngIf="discountLevel!=='0'">
    <div class="col-md-7 align-items-center d-flex">
      <a href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/2024-07-RS{{discountLevel}}.pdf"
         target="_blank">Flyer PDF Juli 2024</a>
    </div>
    <div class="col-md align-items-center d-flex">
      <a class="download-button btn btn-primary btn-round"
         href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/2024-07-RS{{discountLevel}}.pdf"
         target="_blank">
        <span class="arrow-helper far fa-arrow-down"></span>
      </a>
    </div>
  </div>
  <div class="row mt-s" *ngIf="discountLevel==='0'">
    <div class="col-md-7 align-items-center d-flex">
      <a href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/2024-07-UVP.pdf"
         target="_blank">Flyer PDF Juli 2024</a>
    </div>
    <div class="col-md align-items-center d-flex">
      <a class="download-button btn btn-primary btn-round"
         href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/2024-07-UVP.pdf"
         target="_blank">
        <span class="arrow-helper far fa-arrow-down"></span>
      </a>
    </div>
  </div>
  <div class="row mt-s" *ngIf="discountLevel!=='0'">
    <div class="col-md-7 align-items-center d-flex">
      <a href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/2024-07-RS{{discountLevel}}.csv"
         target="_blank">Preise CSV Juli 2024</a>
    </div>
    <div class="col-md align-items-center d-flex">
      <a class="download-button btn btn-primary btn-round"
         href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/2024-07-RS{{discountLevel}}.csv"
         target="_blank">
        <span class="arrow-helper far fa-arrow-down"></span>
      </a>
    </div>
  </div>
</ng-container>
