/**
 * represent the basic navigation item model
 */

export enum NavType {
    MAIN = 'MAIN',
    QUICK = 'QUICK',
    HEADING_ONLY = 'HEADING_ONLY',
    PRIORPORTAL = 'PRIORPORTAL',
    DEINEAPOTHEKESTORE = 'DEINEAPOTHEKESTORE',
    PRIVATSORTIMENT = 'PRIVATSORTIMENT',
    NOTFALLDEPOT = 'NOTFALLDEPOT',
    DISABLED = 'DISABLED',
    VACCINE = 'VACCINE',
    SURVEY = 'SURVEY',
    UBER = 'UBER',
    LINDA = 'LINDA',
    ADVERTORIAL= 'ADVERTORIAL',
    INVOICE = 'INVOICE',
    LINDA_ADMIN = 'LINDA ADMIN',
    CONTEST= 'CONTEST',
    PDF = 'PDF',
    PROMO = 'PROMO',
    COVID = 'COVID'
}

export class NavItem {
    type: NavType;
    heading: string;
    navLinkName: string;
    component: string;
    extUrl: string;
    priorPortalUrl: string;
    path: string;
    activatesPath: string[];
    isTile: boolean;
    tileTitle: string;
    tileImageFileName: string;
    tileImageFileNameHover: string;
    tilePosition: number;
    requiredAuthorities: string[];
    excludedAuthorities: string[];
    tree: NavItem[];
    dataCy: string;
    fragment: string;
    shouldOpenInNewTab: boolean;
    feature: string;
    start: string;
    end: string;
    pageStatus: string;
    class: string;
    visibleForDc: number[];
    showInNav: boolean;

    constructor(obj: any = {}) {
        this.heading = obj && obj.heading || null;
        this.type = obj && obj.type || NavType.MAIN;
        this.navLinkName = obj && obj.navLinkName || null;
        this.component = obj && obj.component || 'dashboard';
        this.extUrl = obj && obj.extUrl || null;
        this.path = obj && obj.path || null;
        this.activatesPath = obj && obj.activatesPath || ['dashboard'];
        this.tree = obj && obj.tree || null;
        this.isTile = obj && obj.isTile || false;
        this.tileTitle = obj && obj.tileTitle || null;
        this.tileImageFileName = obj && obj.tileImageFileName || null;
        this.tileImageFileNameHover = obj && obj.tileImageFileNameHover || null;
        this.tilePosition = obj && obj.tilePosition || null;
        this.requiredAuthorities = obj && obj.requiredAuthorities || null;
        this.excludedAuthorities = obj && obj.excludedAuthorities || null;
        this.dataCy = obj && obj.dataCy || null;
        this.fragment = obj && obj.fragment || null;
        this.priorPortalUrl = obj && obj.priorPortalUrl || null;
        this.shouldOpenInNewTab = obj && obj.shouldOpenInNewTab || false;
        this.feature = obj && obj.feature || false;
        this.start = obj && obj.start || null;
        this.end = obj && obj.end || null;
        this.pageStatus = obj && obj.pageStatus || null;
        this.class = obj && obj.class || '';
        this.visibleForDc = obj && obj.visibleForDc || null;
        if (obj && obj.hasOwnProperty('showInNav')) {
           this.showInNav = obj.showInNav;
        } else {
            this.showInNav = true;
        }
    }

    hasChildNodes() {
        return this.tree && this.tree.length > 0;
    }

    hasTiles() {
        let hasTiles = false;
        this.tree.forEach(childNode => {
            if (childNode.isTile) {
                hasTiles = true;
            }
        });
        return hasTiles;
    }

    hasActiveComponent(component: string) {
        let hasActiveComponent = false;
        this.tree.forEach(childNode => {
            if (childNode.component === component) {
                hasActiveComponent = true;
            }
        });
        return hasActiveComponent;
    }
}
