import {Component, ElementRef, Inject, OnDestroy, QueryList, ViewChildren} from '@angular/core';
import {Nav} from '../../core/models/nav.model';
import {NavType} from '../../core/models/nav-item.model';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UserService} from '../../core/services/user.service';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {IdfSelectionDialogComponent} from '../../modules/shared/idf-selection-dialog/idf-selection-dialog.component';
import {AdsService} from '../../core/services/ads.service';
import {MatomoService} from '../../core/services/matomo.service';
import {TranslateService} from '@ngx-translate/core';
import {UberweiserOpenDialogModel} from '../../modules/shared/uberweiser-open-dialog/uberweiser-open-dialog.model';
import {UberweiserOpenDialogComponent} from '../../modules/shared/uberweiser-open-dialog/uberweiser-open-dialog.component';

import {AdsRestService} from '../../core/services/api/cms/ads.rest-service';
import {NavService} from '../../core/services/nav.service';
import {InternalUserAuthority} from '../../core/models/enums/internal-user-authority.model';
import {
  ProductSearchOpenDialogModel
} from '../../modules/shared/product-search-open-dialog/product-search-open-dialog.model';
import {
  ProductSearchOpenDialogComponent
} from '../../modules/shared/product-search-open-dialog/product-search-open-dialog.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnDestroy {


  // @ViewChildren('toggleArrow', {read: ElementRef}) nodes: QueryList<ElementRef>;
  public nav$: Observable<Nav>;
  public navLoading$: Observable<boolean>;
  public nav: Nav;
  public active: string | boolean;
  public showing: string;
  public menuActive = false;
  public NavType: typeof NavType = NavType;
  public ads = [];
  public surveyLink;
  private idf;
  private selected;
  main;

  mainIdf$: Observable<string>;
  mainIdf: string;
  authTokens$: Observable<string>;
  authToken$: Observable<string>;
  private ngUnsubscribe$ = new Subject<any>();
  public tokens = [];
  public tokenLoading = false;
  public token: string;

  /**
   * @param router
   * @param route
   * @param DA_STORE_URL
   * @param DA_STORE_REFERRER
   * @param PS_URL
   * @param PS_REFERRER
   * @param ND_URL
   * @param ND_REFERRER
   * @param ND_FLAG
   * @param userService
   * @param dialog
   * @param adService
   * @param adsRestService
   * @param matomoService
   * @param translate
   * @param CDN
   * @param navService
   */
    constructor(private router: Router,
                private route: ActivatedRoute,

              @Inject('DA_STORE_URL') private DA_STORE_URL: string,
              @Inject('DA_STORE_REFERRER') private DA_STORE_REFERRER: string,
              @Inject('PS_URL') private PS_URL: string,
              @Inject('PS_REFERRER') private PS_REFERRER: string,
              @Inject('ND_URL') private ND_URL: string,
              @Inject('ND_REFERRER') private ND_REFERRER: string,
              @Inject('ND_FLAG') private ND_FLAG: string,
              @Inject(UserService) private userService: UserService,
              public dialog: MatDialog,
              private adService: AdsService,
              private adsRestService: AdsRestService,
              private matomoService: MatomoService,
              private translate: TranslateService,
              @Inject('CDN') private CDN,
              public navService: NavService
  ) {
    this.nav$ = this.userService.getNavObservable();
    this.navLoading$ = this.userService.getNavLoadingObservable();
    this.authTokens$ = this.userService.getAuthTokensObservable();
    this.authTokens$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((tokens: any) => {
      for (const token in tokens) {
        const item = {key: '', value: ''};
        item.key = token;
        item.value = tokens[token];
        this.tokens.push(item);
        this.tokenLoading = true;
      }
    });
    this.authToken$ = this.userService.getAuthTokenObservable();
    this.authToken$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((token: any) => {
      this.token = token;
    });
    this.mainIdf$ = this.userService.getMainIdfObservable();
    this.mainIdf$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((idf) => {
      this.mainIdf = idf;
    });
    this.router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.determineActive();
      }
    });
    this.nav$.subscribe((nav: Nav) => {
      if (nav) {
        const navCopy = Object.assign(nav);
        this.main = navCopy.tree.filter(link => (link.type === NavType.MAIN && link.showInNav && this.navService.isVisibleForUser(link)));
        this.main.forEach(main => {
          main.tree = main.tree?.filter(link => this.navService.isVisibleForUser(link));
        });
      }

      if (!this.active) {
        const routeSnapshot = this.route.snapshot;
        this.determineActive(routeSnapshot);
        this.determineInitShowing(routeSnapshot);
      }
    });

  }

  determineInitShowing(routeSnapshot) {
    if (routeSnapshot.children.length > 0) {
      this.showing = this.nav
          && this.nav.getParent(routeSnapshot.children[0].routeConfig.path, routeSnapshot.queryParamMap.get('url'));
    }
  }

  determineActive(routeSnapshot = this.route.snapshot) {
    if (routeSnapshot.children.length > 0) {
      if (routeSnapshot.children[0].routeConfig.path === 'dashboard') {
        this.active = routeSnapshot.children[0].routeConfig.path;
        return;
      }
      const changeActive = this.nav
          && this.nav.getProperty(routeSnapshot.children[0].routeConfig.path,
              routeSnapshot.queryParamMap.get('url'), 'path');

      if (routeSnapshot.queryParamMap.has('heading') || !changeActive) {
        this.active = null;
      } else {
        this.active = changeActive;
      }
    }
  }

  redirect() {
    this.router.navigate(['/dashboard']);
    this.router.navigate(['/static-content'],
        {queryParams: {url: '/index.php?id=19', heading: 'Vertriebsinformationen'}});
  }

  openIdfSelectionDialog(navType: NavType, imageFileName: string, heading: string, message: string) {
    const idfSelectionDialogConfig = new MatDialogConfig();
    const authTokens = this.userService.getAuthTokensObservable();
    const authTokensLoading = this.userService.getAuthTokensLoadingObservable();
    const userName = this.userService.getUser().username;

    idfSelectionDialogConfig.width = '50vw';
    idfSelectionDialogConfig.data = {
      navType: navType,
      userName: userName,
      imageFileName: imageFileName,
      heading: heading,
      message: message,
      authTokens: authTokens,
      authTokensLoading: authTokensLoading
    };

    const dialogRef = this.dialog.open(IdfSelectionDialogComponent, idfSelectionDialogConfig);
    // this.menuInactive();
  }

  canShowContainer(node) {
    let show = false;

    show = node.requiredAuthorities === null || this.userService.hasRequiredAuthority(node.requiredAuthorities);

    return show;
  }

  isQa(): boolean {
    return environment.qa;
  }

  isDev(): boolean {
    return environment.dev;
  }

  isLocal(): boolean {
    return !(environment.dev || environment.production || environment.qa);
  }

  isDebugMode(): boolean {
    return environment.isDebugMode;
  }

  isAuthorized(): boolean {
    return this.userService.canInternalUserChangeIdfSelection(InternalUserAuthority.SALES_INFORMATION);
  }

  mtmEvent(action) {

    this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'Klick auf Navigation', this.translate.instant(action));

  }

  showDialog(auths) {
    return auths.length > 1;
  }

  showOpenUberweiserDialog() {
    const dialogData = new UberweiserOpenDialogModel('Bitte beachten!', 'Sie öffnen einen Bereich innerhalb des Apothekenportal, ' +
        'in dem ausschließlich Herstellerangebote/Überweiseraktionen abgebildet werden.' +
        `</br><b>Bitte bestätigen Sie dies mit OK</b>`);
    const dialogRef = this.dialog.open(UberweiserOpenDialogComponent, {
      width: '55vw',
      closeOnNavigation: true,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.router.navigateByUrl('/uberweiser');
      }
    });
  }

  dropdownHover($event: any) {
    let link = $event.target as HTMLElement;
    let a = (link).getElementsByClassName('dropdown-toggle')[0];
    if (link.hasAttribute('data-toggle')) {
      a = link;
      link = a.parentElement as HTMLElement;
    }

    const dropdown = (link).getElementsByClassName('dropdown-menu');
    console.log(a);
    console.log(dropdown);
    if (dropdown.length) {
      a.setAttribute('aria-expanded', 'true');
      dropdown[0].className = 'dropdown-menu show';
    }
  }

  dropdownUnHover($event: any) {
    let link = $event.target as HTMLElement;
    let a = (link).getElementsByClassName('dropdown-toggle')[0];
    if (link.hasAttribute('data-toggle')) {
      a = link;
      link = a.parentElement as HTMLElement;
    }
    const dropdown = (link).getElementsByClassName('dropdown-menu show');
    if (dropdown.length) {
      a.setAttribute('aria-expanded', 'true');
      dropdown[0].className = 'dropdown-menu';
    }
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getLocation(type) {
    if (type === NavType.DEINEAPOTHEKESTORE) {
      return this.DA_STORE_URL + '?user=' + this.userService.getUser().username + '&token=' + this.tokens[0].value +
          '&idf=' + this.tokens[0].key + '&referrer=' + this.DA_STORE_REFERRER;
    }

    if (type === NavType.PRIVATSORTIMENT) {
      return this.PS_URL + '?user=' + this.userService.getUser().username + '&token=' + this.token +
          '&idf=' + this.mainIdf + '&referrer=' + this.PS_REFERRER;
    }
  }

  showOpenProductSearchDialog() {
    const dialogData = new ProductSearchOpenDialogModel(
        'https://cspapdsacc.blob.core.windows.net/marketing-management/promotion/2024-03_popup_promotion_move.jpg',
        'Die Online Sparangebote sind umgezogen!',
        'Ab sofort finden Sie alle Online Sparangebote im neuen Produktshop! So haben Sie die Recherche nach Ihren Wunschartikeln und alle Preisvorteile direkt an einem Ort verfügbar:');
    const dialogRef = this.dialog.open(ProductSearchOpenDialogComponent, {
      maxWidth: '600px',
      closeOnNavigation: true,
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.router.navigateByUrl('/shop');
      }
    });
  }

  getHeading(type) {
    if (type === NavType.DEINEAPOTHEKESTORE) {
      return 'Anmeldung im deine Apotheke Store';
    }

    if (type === NavType.PRIVATSORTIMENT) {
      return 'Anmeldung im deine Apotheke Store';
    }
  }

  getMessage(type) {
    if (type === NavType.DEINEAPOTHEKESTORE) {
      return 'Wählen Sie die gewünschte IDF zur Anmeldung im deine Apotheke Store aus';
    }
  }

  toLindaGVL() {
    if (sessionStorage.getItem('gvl')) {
      window.location.href = sessionStorage.getItem('gvl')
    }
  }

  protected readonly sessionStorage = sessionStorage;
}
